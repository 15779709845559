.project-container {
    padding: 2em 2em;
    max-width: var(--max-width);
    margin: auto;
}

.project-preview {
    object-fit: cover;
    border: 4px solid var(--light-blue);
    margin-bottom: 0;
    border-radius: 10px;
}

.project-title {
    margin: 2em 0;
}

.project-text {
    font-size: var(--fs-400);
    font-weight: 400;
    text-align: left;
    margin-bottom: 1em;
    color: var(--text-white);
}

figcaption {
    margin: -1em;
    padding-top: 1.5em;
    padding-bottom: 1em;
    color: var(--yellow);
    text-align: center;
    background-color: var(--light-blue);
    border-radius: 20px;
    font-style: italic;
    margin-bottom: 3em;
}

.project-link {
    text-decoration: none;
    display: block;
    background: var(--light-blue);
    text-align: center;
    padding: 1em;
    color: var(--text-white);
    margin: 3em 0;
    border-radius: 20px;
    transition: all 200ms ease-in-out;
}

.project-link:hover,
.project-link:focus {
    background-color: var(--red);
    color: var(--bg-black);
    text-decoration: none;
}

code {
    background-color: black;
    border-radius: 5px;
    padding: .03em .4em;
}