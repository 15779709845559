@import "https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&family=Roboto:wght@300;400;700&display=swap";
.navbar {
  text-align: left;
  z-index: -1;
  background-color: var(--yellow);
  width: 100%;
  transition: top .8s ease-in-out .1s;
  position: absolute;
  top: -250%;
  left: 0;
}

.nav-link-container {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 450px) {
  .nav-link-container {
    max-width: 800px;
    justify-content: space-around;
    margin: auto;
    display: flex;
  }
}

.nav-toggled {
  top: 100%;
}

.nav-li {
  text-align: center;
  margin-bottom: 1em;
}

.nav-a {
  text-transform: uppercase;
  color: #fff;
  font-size: 1.4rem;
  text-decoration: none;
  position: relative;
}

.nav-a:before {
  content: "";
  width: 0%;
  height: 3px;
  background: #fff;
  transition: width .1s ease-in-out;
  position: absolute;
  bottom: 0;
}

.nav-a:hover:before, .nav-a:focus:before {
  width: 100%;
}

.hero {
  min-height: calc(100vh - 7em);
  background: radial-gradient(circle, #292831 0%, #4a7a96 89%);
  align-items: center;
  justify-items: center;
  padding: 8em 1em;
  display: grid;
}

.hero:nth-child(2n) {
  background: var(--light-blue);
}

.hero-column-outter-container {
  width: 100%;
  max-width: var(--max-width);
}

.hero-column-container, .hero-small-column-container {
  gap: 1.5em;
  margin-bottom: 2.5em;
  display: grid;
}

.hero-column-container {
  grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
}

.hero-small-column-container {
  grid-template-columns: repeat(2, minmax(5em, 1fr));
}

@media (min-width: 30em) {
  .hero-small-column-container {
    grid-template-columns: repeat(auto-fit, minmax(5em, 1fr));
  }
}

.all-row {
  grid-column: 1 / -1;
}

.hero-title {
  color: var(--text-white);
  font-size: var(--fs-xl);
  text-align: center;
  margin: 0 .5em .5em;
  font-weight: 600;
}

.hero-main-title {
  font-size: var(--fs-600);
  color: var(--yellow);
  margin-bottom: 1em;
}

.hero-sub-title {
  color: var(--red);
}

.hero-text {
  font-size: var(--fs-400);
  margin-bottom: 1em;
}

.centered {
  text-align: center;
}

.hero:nth-child(2n+1) p {
  color: var(--text-white);
}

.hero-em {
  z-index: 1;
  position: relative;
}

.hero-em:before {
  content: "";
  height: 100%;
  width: 80%;
  background: var(--red);
  z-index: -1;
  position: absolute;
  top: 0;
  left: 10%;
  transform: skew(-15deg, -3deg);
}

.hero-link {
  color: inherit;
  text-decoration: none;
  transition: color .2s ease-in-out;
  display: block;
}

.hero-link:hover, .hero-link:focus {
  text-decoration: none;
}

svg {
  color: var(--yellow);
  transition: color .2s ease-in-out;
}

.github svg:hover, .github svg:focus {
  color: #cdd9e5;
}

.linkedin svg:hover, .linkedin svg:focus {
  color: #0a66c2;
}

.email svg:hover, .email svg:focus {
  color: #a10101;
}

.hero-code {
  color: #fff;
  font-family: Roboto Mono, monospace;
  font-size: var(--fs-500);
  text-align: center;
  margin: 0 .7em;
}

.carret {
  animation-name: blink;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.card-container {
  height: 100%;
  background: var(--dark-blue);
  border-radius: .7em;
  flex-direction: column;
  padding: 1em;
  transition: box-shadow .2s ease-in-out;
  display: flex;
}

.card-container:hover, .card-container:focus {
  box-shadow: 0 0 1em var(--light-blue);
}

.card-title {
  font-size: var(--fs-500);
  color: var(--yellow);
  text-align: center;
  margin-bottom: .6em;
}

.card-link {
  color: var(--red);
  font-size: var(--fs-500);
  margin-bottom: .6em;
  text-decoration: none;
  position: relative;
}

.card-link:hover, .card-link:focus {
  text-decoration: none;
}

.card-description {
  font-size: var(--fs-400);
  margin-bottom: 1.2em;
}

.card-preview-container {
  width: 100%;
  max-height: 200px;
  border: 1px solid var(--yellow);
  border-radius: 10px;
  margin-bottom: 1.2em;
}

.card-preview {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.project-container {
  max-width: var(--max-width);
  margin: auto;
  padding: 2em;
}

.project-preview {
  object-fit: cover;
  border: 4px solid var(--light-blue);
  border-radius: 10px;
  margin-bottom: 0;
}

.project-title {
  margin: 2em 0;
}

.project-text {
  font-size: var(--fs-400);
  text-align: left;
  color: var(--text-white);
  margin-bottom: 1em;
  font-weight: 400;
}

figcaption {
  color: var(--yellow);
  text-align: center;
  background-color: var(--light-blue);
  border-radius: 20px;
  margin: -1em -1em 3em;
  padding-top: 1.5em;
  padding-bottom: 1em;
  font-style: italic;
}

.project-link {
  background: var(--light-blue);
  text-align: center;
  color: var(--text-white);
  border-radius: 20px;
  margin: 3em 0;
  padding: 1em;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: block;
}

.project-link:hover, .project-link:focus {
  background-color: var(--red);
  color: var(--bg-black);
  text-decoration: none;
}

code {
  background-color: #000;
  border-radius: 5px;
  padding: .03em .4em;
}

.timeline {
  --gradient-colors: var(--yellow) 0%, rgba(0, 0, 0, 0) 80%;
  width: 90%;
  margin: auto;
}

@media (min-width: 800px) {
  .timeline {
    width: 70%;
  }
}

.timeline-event {
  border-left: 10px solid var(--yellow);
}

.timeline-event:hover {
  background-color: rgba(0, 0, 0, .07);
}

.timeline-end {
  height: 6em;
  width: 10px;
  background: var(--yellow);
  background: linear-gradient(0deg, var(--gradient-colors) );
}

.inverted {
  background: var(--yellow);
  background: linear-gradient(180deg, var(--gradient-colors) );
}

.timeline-title {
  color: var(--yellow);
  font-size: var(--fs-500);
  padding-left: 2em;
  position: relative;
}

.timeline-title:before {
  content: "";
  height: 26px;
  width: 26px;
  background-color: var(--yellow);
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: -18px;
}

.timeline-extract, .timeline-text {
  color: var(--text-white);
  line-height: 1.5;
  font-size: var(--fs-400);
  margin-left: 2em;
}

.timeline-text {
  margin-left: 2.3em;
  font-size: 1.1rem;
  line-height: 1.7;
  box-shadow: inset .2em -.2em .4em rgba(0, 0, 0, .4), inset -.2em -.2em .4em rgba(0, 0, 0, .4);
}

.timeline-extract {
  border-bottom: solid 1px var(--dark-blue);
  padding-bottom: 1em;
}

.timeline-text {
  max-height: 0;
  background: var(--dark-blue);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0 2em;
  transition: all .8s ease-in-out;
  overflow: hidden;
}

.text-toggled .timeline-text {
  max-height: 1000px;
  padding: 1.5em 2em;
}

.btn-more {
  margin-left: .4em;
  display: inline;
}

.btn-more svg {
  font-size: 1.1rem;
}

.fa-circle-info {
  color: var(--text-white);
}

header {
  width: 100%;
  z-index: 1000;
  background-color: var(--yellow);
  text-align: center;
  position: fixed;
}

.logo-img {
  height: 4em;
  margin: 1em;
  transition: transform .8s ease-in-out;
  transform: rotate(0deg);
}

.logo-reversed {
  transform: rotate(900deg);
}



*, :before, :after {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --max-width: 1000px;
  --yellow: #fbbbad;
  --red: #ee8695;
  --light-blue: #4a7a96;
  --dark-blue: #333f58;
  --bg-black: #292831;
  --text-white: #eaeaea;
  --fs-xl: clamp(1.5rem, 6vw + 1rem, 5rem);
  --fs-600: 1.45rem;
  --fs-500: clamp(1rem, 2.3vw + 1rem, 2rem);
  --fs-400: 1.1rem;
}

@media (min-width: 750px) {
  :root {
    --fs-600: 1.8rem;
    --fs-400: 1.3rem;
  }
}

html {
  scroll-behavior: smooth;
}

main {
  padding-top: 6.45em;
}

@media (min-width: 500px) {
  main {
    padding-top: 7em;
  }
}

body {
  text-rendering: optimizelegibility;
  background: var(--bg-black);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

a {
  color: var(--yellow);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
}

/*# sourceMappingURL=index.3717961c.css.map */
